import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';

@Component({
  selector: 'app-websdk',
  templateUrl: './websdk.component.html',
  styleUrls: ['./websdk.component.css']
})
export class WebsdkComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

   // Method to dynamically load JavaScript 
   loadScripts() {   
     // This array contains all the files/CDNs 
     const dynamicScripts = [ 
        'assets/js/main.js'
     ]; 
     for (let i = 0; i < dynamicScripts.length; i++) { 
       const node = document.createElement('script'); 
       node.src = dynamicScripts[i]; 
       node.type = 'text/javascript'; 
       node.async = false; 
       document.getElementsByTagName('head')[0].appendChild(node); 
     } 
  } 

}
