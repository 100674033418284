import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
