import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CustomService } from '../../../services/custom.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
  	private cService: CustomService,
    private titleService: Title,
    private metaService: Meta
  ) {
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
    /*this.titleService.setTitle('About');
    this.metaService.addTags([
      {name: 'keywords', content: 'About, Founder, CEO'},
      {name: 'description', content: 'Datonique Founder and CEO'},
      {name: 'robots', content: 'index, follow'}
    ]);*/
  }

}
