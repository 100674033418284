<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Products</h2>
					<p>Data Infrastructure | Data Warehouse </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="blog-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
					
					
					
					<div class="sb-widget">
						<div class="info-box" >
							<h3>Contact Us for Help</h3>
							<p style="color:#f1f1f1;">You can contact us to know more about our products and services. </p>
							<div class="footer-info-box">
								<div class="fib-icon">
									<img src="assets/img/icons/phone.png" alt="" class="">
								</div>
								<div class="fib-text" >
									<p  style="color:#f1f1f1;">626-240-4600<br>
<a href="mailto:hello@datonique.com" style="text-decoration:none;">hello@datonique.com</a></p>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 order-1 order-lg-2">
					<div class="blog-post">
						<div class="blog-thumb set-bg" data-setbg="assets/img/blog/4.jpg">
							
						</div>
						
						<h2>Data Warehouse</h2>
						<p>We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have a team of in-house experts and a network of capable professionals that we constantly tap into at our disposal for seamless integration and execution.</p>

<p>We have developed Unified Cloud Data Warehouses for several clients integrating all their data sources into one single instance seamlessly. We develop a solution that fits the client’s current stack, needs and vision.
</p>
						
						
					</div>
					
					
					
				</div>
			</div>
		</div>
	</section>