<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Testimonials</h2>
					<p>About Us | Testimonials </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="about-section">
	<div class="testimonial-box">
						<div class="testi-box-warp">
							<h2>Testimonials</h2>
							<div class="testimonial-slider owl-carousel" >
								<div class="testimonial">
									<p>Murali and his team at Datonique continually impress me with their ability to take complex, messy data and transform it into invaluable insights. On top of that, they’re an absolute pleasure to work with.</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Gavin Chan</h5>
										<span>Chief Operating Officer at Hippo Education</span>
									</div>
								</div>
								<div class="testimonial">
									<p>Murali is one of my favorite people. He was VP, Head of Analytics at Victorious where he was responsible for putting together a world class data infrastructure and analytics framework. We were able to learn a lot about our product and users in part due to his awesome product acumen and strategic mindset.</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Sam Rogoway </h5>
										<span>Chief Product & Content Officer at Headspace, formerly CEO of Victorious </span>
									</div>
								</div>
								<div class="testimonial">
									<p>The comment has been made around TeleSign by people working with Murali for the first time that it’s really refreshing to have someone who is fluent in system architecture and data reporting who has such strong business acumen. The combination is really difficult to find.</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Tim Weerasiri</h5>
										<span>Chief Financial Officer at TeleSign Corporation</span>
									</div>
								</div>
								<div class="testimonial">
									<p>As Founder and CEO of Datonique, Murali is making the entire world data literate. I cannot wait to see the fantastic analytics products he is developing right now. Datonique is the quintessential data analytics and digital transformation powerhouse.</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Sam Rogoway</h5>
										<span>Chief Product & Content Officer at Headspace, formerly CEO of Victorious</span>
									</div>
								</div>
								<div class="testimonial">
									<p>After coming on board to help me, Murali has been pulled into a number of other workflows because just having him there to provide insights from multiple perspectives is invaluable.</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Tim Weerasiri</h5>
										<span>Chief Financial Officer at TeleSign Corporation</span>
									</div>
								</div>
								<div class="testimonial">
									<p>Datonique is an invaluable partner in helping Full Stack Finance financially and analytically support early stage companies. Their unique blend of business and technology insights of data infrastructures help efficiently set up the analytical framework and instrumentation necessary for early stage companies to be successful.

</p>
									<img src="assets/img/testimonial-thumb.jpg" alt="" class="testi-thumb">
									<div class="testi-info">
										<h5>- Rick Gombos</h5>
										<span>Partner and CFO at Full Stack Finance</span>
									</div>
								</div>
							</div>
						</div>
					</div>
		
	</section>