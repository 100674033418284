import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {Location, LocationStrategy, HashLocationStrategy} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { ToastrModule } from 'ngx-toastr';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/site/home/home.component';
import { WebsdkComponent } from './components/site/websdk/websdk.component';
import { MobilesdkComponent } from './components/site/mobilesdk/mobilesdk.component';
import { WarehouseComponent } from './components/site/warehouse/warehouse.component';
import { StagingplatformComponent } from './components/site/stagingplatform/stagingplatform.component';
import { StandardportalComponent } from './components/site/standardportal/standardportal.component';
import { CustomportalComponent } from './components/site/customportal/customportal.component';
import { DatainfraComponent } from './components/site/datainfra/datainfra.component';
import { ReportingComponent } from './components/site/reporting/reporting.component';
import { DatascienceComponent } from './components/site/datascience/datascience.component';
import { MarketingComponent } from './components/site/marketing/marketing.component';
import { CustomerComponent } from './components/site/customer/customer.component';
import { ContactusComponent } from './components/site/contactus/contactus.component';
import { MissionComponent } from './components/site/mission/mission.component';
import { TeamComponent } from './components/site/team/team.component';
import { TestmonialsComponent } from './components/site/testmonials/testmonials.component';
import { CareersComponent } from './components/site/careers/careers.component';
import { AnalyticsdkComponent } from './components/site/analyticsdk/analyticsdk.component';
import { DatainfrastructureComponent } from './components/site/datainfrastructure/datainfrastructure.component';
import { ReportingportalComponent } from './components/site/reportingportal/reportingportal.component';
import { ServicesComponent } from './components/site/services/services.component';
import { DigitaltransformComponent } from './components/site/digitaltransform/digitaltransform.component';
import { ResourcesComponent } from './components/site/resources/resources.component';
import { AboutComponent } from './components/site/about/about.component';
import { DatanalyticsComponent } from './components/site/datanalytics/datanalytics.component';
import { ProductsComponent } from './components/site/products/products.component';
import { SearchComponent } from './components/site/search/search.component';
import { BlogComponent } from './components/site/blog/blog.component';
import { BlogDetailComponent } from './components/site/blog-detail/blog-detail.component';

import { environment } from 'src/environments/environment'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    WebsdkComponent,
    MobilesdkComponent,
    WarehouseComponent,
    StagingplatformComponent,
    StandardportalComponent,
    CustomportalComponent,
    DatainfraComponent,
    ReportingComponent,
    DatascienceComponent,
    MarketingComponent,
    CustomerComponent,
    ContactusComponent,
    MissionComponent,
    TeamComponent,
    TestmonialsComponent,
    CareersComponent,
    AnalyticsdkComponent,
    DatainfrastructureComponent,
    ReportingportalComponent,
    ServicesComponent,
    DigitaltransformComponent,
    ResourcesComponent,
    AboutComponent,
    DatanalyticsComponent,
    ProductsComponent,
    SearchComponent,
    BlogComponent,
    BlogDetailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    ToastrModule.forRoot(), // ToastrModule added
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [Title, {provide : LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
