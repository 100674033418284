import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import { CustomService } from '../../../services/custom.service';   
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BlogDetailComponent implements OnInit {
  
  paramsSub:any;
  tagsArr = ["datonique","analytics","portal","report","sql query","data","new currency","unify","leverage data","tracking","metrics","definition","measurement","kpis","customer behavior","engagement","data integrity","hygiene","clean data","roi","data ftw","revenue value of data","revenue","not a cost center","data drives revenue"];
  message = {
    "title":"",
    "author":"",
    "date":"",
    "content":"",
    "tags":[]
  };
  blogtags = 'All';

  constructor(
    private http: HttpClient,
    private router: Router,
    private actRouter: ActivatedRoute,
    private titleService: Title,
    private cService: CustomService
  ) { 
    this.cService.onLoadScript(); 
    this.paramsSub = this.actRouter.params.subscribe(params => {
      if(params.id != undefined && params.title != undefined) {
        this.getBlog(params.id);
      }
    });
  }

  ngOnInit(): void {
    
  }

  searchTags() {
    if(this.blogtags !== 'All') {
      this.router.navigate(['/Blog'], { queryParams: { tag: this.blogtags } });
    } else {
      this.router.navigate(['/Blog']);
    }
  }

  getBlog(id: any) {
    this.http.get<any>(environment.localPath+id+'.json').subscribe(data => {
        this.message = data[0];
        this.titleService.setTitle( "Datonique - "+this.message.title );
    });
  }

}
