import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service'; 

@Component({
  selector: 'app-standardportal',
  templateUrl: './standardportal.component.html',
  styleUrls: ['./standardportal.component.css']
})
export class StandardportalComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
