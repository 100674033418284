<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>About us</h2>
					<p>Founder </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="about-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<img src="assets/img/about.jpg" alt="" class="img-thumbnail">
				</div>
				<div class="col-lg-6">
					<div class="about-text">
						
						<p>Murali is a seasoned analytics professional, entrepreneur, product and strategy leader with 10+ years of experience in the technology space leading multiple teams across various companies in all spheres of the industry.</p>

<p>Murali started his career as a management consultant for Deloitte Consulting and then doing analytics for search and display monetization at Yahoo. Prior to founding Datonique, Murali was Vice President and Head of Analytics and Business Intelligence for Victorious Inc, working at the intersection of  technology, content and entertainment for influencers. Prior to that he headed up the data and analytics team in multiple companies in Digital Advertising, Mobile Gaming and Mobile Platforms.</p>

<p>Murali was born and raised in India where he got an undergraduate degree from IIT Mumbai and then moved to UCLA in Los Angeles, CA for graduate school. He  is a mentor and advisor for multiple companies around the globe on strategy, product and  analytics.
</p>
						<div class="about-sign">
							<div class="sign">
								<img src="assets/img/sign.png" alt="">
							</div>
							<div class="sign-info">
								<h5 style="margin-right:0px !important;">Murali Vasudevan</h5>
								<span>Founder and CEO</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>