<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Services</h2>
					<p>Data Analytics | Data Infrastructure </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="blog-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
					
					
					
					<div class="sb-widget">
						<div class="info-box" >
							<h3>Contact Us for Help</h3>
							<p style="color:#f1f1f1;">You can contact us to know more about our products and services. </p>
							<div class="footer-info-box">
								<div class="fib-icon">
									<img src="assets/img/icons/phone.png" alt="" class="">
								</div>
								<div class="fib-text" >
									<p  style="color:#f1f1f1;">626-240-4600<br>
<a href="mailto:hello@datonique.com" style="text-decoration:none;">hello@datonique.com</a></p>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 order-1 order-lg-2">
					<div class="blog-post">
						<div class="blog-thumb set-bg" data-setbg="assets/img/blog/14.png">
							
						</div>
						
						<h2>Data Infrastructure</h2>
						<p>The first pillar is establishing data infrastructure. It involves setting up the data instruments and production systems to siphon data in readable format into a robust data warehouse through automated ETL processes.</p>
						
						
					</div>
					
					
					
				</div>
			</div>
		</div>
	</section>