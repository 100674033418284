import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';   

@Component({
  selector: 'app-datainfra',
  templateUrl: './datainfra.component.html',
  styleUrls: ['./datainfra.component.css']
})
export class DatainfraComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
