<app-header></app-header>
<ngx-loading-bar [color]="'red'"></ngx-loading-bar> 	
<router-outlet #o="outlet"></router-outlet>
<app-footer></app-footer>

<!-- Search model -->
<div class="search-model">
  <div class="h-100 d-flex align-items-center justify-content-center">
    <div class="search-close-switch">+</div>
    
  </div>
</div>
<!-- Search model end -->