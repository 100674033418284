<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Careers</h2>
					<p>About Us | Careers </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="blog-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
					
					
					
					<div class="sb-widget">
						<div class="info-box" >
							<h3>Feel Free to email us</h3>
							<p style="color:#f1f1f1;">Please email your resume to below mentioned email with the title in the subject line of your email.</p>
							<div class="footer-info-box">
								<div>
									
								</div>
								<div class="fib-text" >
									<p  style="color:#f1f1f1;"> <i class="fa fa-envelope" style="padding-right:10px; font-size:1.5em;"></i> <a href="mailto:hiring@datonique.com" style="text-decoration:none;">hiring@datonique.com</a></p>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 order-1 order-lg-2">
					<div class="blog-post">
						
						<h2>Careers</h2>
						<p>We are always looking for talented people to join our team. Here are the positions we are hiring for</p>
						
						<div class="element">
				
				<div class="row">
					
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-code"></i></div>
							<h5>Backend Engineer</h5>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-setting"></i></div>
							<h5>Data Engineer</h5>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-labor"></i></div>
							<h5>Data Scientist</h5>
							
						</div>
					</div>
				</div>
				<div class="row" style="padding-top:50px;">
					
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-scientist"></i></div>
							<h5>Analyst</h5>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-manager"></i></div>
							<h5>Product Manager</h5>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6">
						<div style="text-align:center !important;">
						<div class="circle"><i class="icon-architect"></i></div>
							<h5>Solutions Architect</h5>
							
						</div>
					</div>
				</div>
			</div>
					</div>
					
					
					
				</div>
			</div>
		</div>
	</section>