<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Services</h2>
					<p>Home | Services </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="reserch-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<ul class="nav nav-tabs reserch-tab-menu" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true">Customer Success Stories</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false">Contact Us</a>
						</li>
						
						
					</ul>
				</div>
				<div class="col-lg-8">
					<div class="tab-content reserch-tab">
						<!-- single tab content -->
						<div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
							<h2>Data Analytics</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Data Infrastructure</p>

<p style="padding-top:0px;">The first pillar is establishing data infrastructure. It involves setting up the data instruments and production systems to siphon data in readable format into a robust data warehouse through automated ETL processes.</p>


<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Reporting and Visualization</p>

<p>The second pillar is to set up analytics and reporting tools that would enable data literacy by defining key success metrics (also called Key Performance Indicators or KPIs) for the company. This will help tune the product/business on some quick insights based on reporting. This pillar includes reporting tool development, establishing KPIs and data visualization.</p>
<p  style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Insights and Data Science</p>
<p>The third pillar involves driving deeper insights, data science initiatives (AI/Machine Learning/Predictive Modeling) that ask and answer some difficult questions that require the use of sophisticated analyses such as predictive analytics, regression modeling, principal component analysis (PCA), Machine Learning etc. These are more involved and usually require (1) and (2) in steady state to be undertaken (much later in the life cycle).</p>
							
							
						</div>
						<div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
							<h2>Digital Transformation</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Digital Transformation</p>

<p style="padding-top:0px;">We are experts in building new systems, overhauling or replacing archaic systems and technologies with scalable and futuristic systems through an organized and seasoned approach that has proven to be successful over several years of development.</p>





						</div>
						
						<div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
							<h2>Data Science</h2>
							<p style="color:#e58202; font-weight:600; font-size:1.2em; margin-bottom:10px; margin-top:30px;" >Insights and Data Science</p>

<p style="padding-top:0px;">The third pillar involves driving deeper insights, data science initiatives (AI/Machine Learning/Predictive Modeling) that ask and answer some difficult questions that require the use of sophisticated analyses such as predictive analytics, regression modeling, principal component analysis (PCA), Machine Learning etc. These are more involved and usually require (1) and (2) in steady state to be undertaken (much later in the life cycle).</p>





						</div>
						
						
					</div>
				</div>
			
				
			</div>
		</div>
	</section>