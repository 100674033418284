<!-- <section class="blog-section spad">
	<div class="container">
		<div style="display: none;" [innerHtml]="gcseSearchBox"></div>
		<div #srchResult [innerHtml]="gcseSearchResult"></div>
	</div>
</section> -->
<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Search</h2>
					<!-- <p>About Us | Careers </p> -->
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->
<section class="blog-section" style="padding-top: 30px;">
	<div class="container bootstrap snippet">
	    <div class="row">
	        <div class="col-lg-12">
	            <div class="ibox float-e-margins">
	                <div class="ibox-content">
	                    <h4>
	                        {{ srchResult.length }} results found for: <span class="text-navy">"{{ srchTxt }}"</span>
	                    </h4>
	                    <!-- <small>Request time  (0.23 seconds)</small> -->
	        			<br />
	                    <div class="search-form">
	                        <form (ngSubmit)="goSearch(searchTxt.value)">
	                            <div class="input-group">
	                                <input #searchTxt type="text" placeholder="Search" name="search" class="form-control input-lg">
	                                <div class="input-group-btn">
	                                    <button class="btn btn-primary" type="submit">
	                                        Search
	                                    </button>
	                                </div>
	                            </div>
	                        </form>
	                    </div>
	                    <br />
	                    <div *ngFor="let srow of srchResult">
		                    <div class="hr-line-dashed"></div>
		                    <div class="search-result">
		                        <h5><a href="javascript://" routerLinkActive="active" routerLink="/{{ srow.path }}">{{ srow.title }}</a></h5>
		                        <a href="javascript://" routerLinkActive="active" routerLink="/{{ srow.path }}" class="search-link">https://datonique.com/#/{{ srow.path }}</a>
		                        <p>
		                          {{ srow.short_desc }}
		                        </p>
		                    </div>
	                    </div>
	                    
	                </div>
	            </div>
	        </div>
	    </div>
	</div>
</section>