// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiKey: 'JGIji7rUC69xIcP7ox60k8TyY28pLWm2aLGNUsbu',
  stage: 'prod',
  gsID: 'b4020ef8b508ac2bd',
  ga: 'G-E3H1S08R98',
  localPath: 'assets/json/',
  //ApiPath: '/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
