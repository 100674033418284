<!-- Page top section  -->
<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <h2>Blog</h2>
                <!-- <p>Datonique Web  </p> -->
            
            </div>
        </div>
    </div>
</section>
<!-- Page top section end  -->


<!-- About section -->
<section class="blog-section spad">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-8 order-1 order-lg-1">
                <article class="article">
                    <!-- <div class="article-img">
                        <img src="https://via.placeholder.com/800x350/87CEFA/000000" title="" alt="">
                    </div> -->
                    <div class="article-title">
                        <h6><a href="javascript://" routerLink="/Blog">Back</a></h6>
                        <h2>{{message.title}}</h2>
                        <div class="media">
                            <div class="avatar">
                                <img src="https://bootdey.com/img/Content/avatar/avatar1.png" title="" alt="">
                            </div>
                            <div class="media-body">
                                <label>{{message.author}}</label>
                                <span>{{message.date}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="article-content" [innerHTML]="message[0].content"></div> -->
                    <div class="article-content" [innerHTML]="message.content">
                        
                    </div>
                    <div class="nav tag-cloud">
                        <a href="javascript://" *ngFor="let tag of message.tags; let i = index">{{tag}}</a>
                    </div>
                </article>

            </div>

            <div class="col-lg-4 col-md-6 sidebar order-2 order-lg-2">
                <div class="sb-widget">
                    <div class="info-box" >
                        <div class="p-3">
                            <h3>Tags</h3>
                            <div class="input-group mb-3">
                                <select [(ngModel)]="blogtags" class="form-select form-select-sm" style="width: 80%;" aria-label=".form-select-sm example">
                                    <option [ngValue]="'All'">All</option>
                                    <option [ngValue]="trow" *ngFor="let trow of tagsArr;">{{trow}}</option>
                                </select>
                                <div class="input-group-append">
                                  <button (click)="searchTags();" class="btn btn-outline-secondary btn-primary text-white" type="button" id="button-addon2">GO</button>
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <h3>Archives</h3>
                            <ol class="list-unstyled mb-0">
                                <li><a href="javascript://" [routerLink]="['/Blog']">All</a></li>
                                <li><a href="javascript://" [routerLink]="['/Blog']" [queryParams]="{ date: 'OCT, 2021'}">October 2021</a></li>
                                <li><a href="javascript://" [routerLink]="['/Blog']" [queryParams]="{ date: 'SEP, 2021'}">September 2021</a></li>
                                <li><a href="javascript://" [routerLink]="['/Blog']" [queryParams]="{ date: 'AUG, 2021'}">August 2021</a></li>
                                <li><a href="javascript://" [routerLink]="['/Blog']" [queryParams]="{ date: 'JUL, 2021'}">July 2021</a></li>
                            </ol>
                          </div>
                
                          <!-- <div class="p-3">
                            <h3>Elsewhere</h3>
                            <ol class="list-unstyled">
                              <li><a href="javascript://">GitHub</a></li>
                              <li><a href="javascript://">Twitter</a></li>
                              <li><a href="javascript://">Facebook</a></li>
                            </ol>
                          </div> -->
                        
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</section>