import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/site/home/home.component';
import { WebsdkComponent } from './components/site/websdk/websdk.component';
import { MobilesdkComponent } from './components/site/mobilesdk/mobilesdk.component';
import { WarehouseComponent } from './components/site/warehouse/warehouse.component';
import { StagingplatformComponent } from './components/site/stagingplatform/stagingplatform.component';
import { StandardportalComponent } from './components/site/standardportal/standardportal.component';
import { CustomportalComponent } from './components/site/customportal/customportal.component';
import { DatainfraComponent } from './components/site/datainfra/datainfra.component';
import { ReportingComponent } from './components/site/reporting/reporting.component';
import { DatascienceComponent } from './components/site/datascience/datascience.component';
import { MarketingComponent } from './components/site/marketing/marketing.component';
import { CustomerComponent } from './components/site/customer/customer.component';
import { ContactusComponent } from './components/site/contactus/contactus.component';
import { MissionComponent } from './components/site/mission/mission.component';
import { TeamComponent } from './components/site/team/team.component';
import { TestmonialsComponent } from './components/site/testmonials/testmonials.component';
import { CareersComponent } from './components/site/careers/careers.component';
import { AnalyticsdkComponent } from './components/site/analyticsdk/analyticsdk.component';
import { DatainfrastructureComponent } from './components/site/datainfrastructure/datainfrastructure.component';
import { ReportingportalComponent } from './components/site/reportingportal/reportingportal.component';
import { ServicesComponent } from './components/site/services/services.component';
import { DigitaltransformComponent } from './components/site/digitaltransform/digitaltransform.component';
import { ResourcesComponent } from './components/site/resources/resources.component';
import { AboutComponent } from './components/site/about/about.component';
import { DatanalyticsComponent } from './components/site/datanalytics/datanalytics.component';
import { ProductsComponent } from './components/site/products/products.component';
import { SearchComponent } from './components/site/search/search.component';
import { BlogComponent } from './components/site/blog/blog.component';
import { BlogDetailComponent } from './components/site/blog-detail/blog-detail.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: HomeComponent, 
    data : {seo: {
        title: 'Home',
        metaTags: [
          { name: 'description', content: 'Datonique specializes in data analytics, digital transformation and growth marketing. Our products are analytics sdk, reporting portal and data warehouse.' },
          { name: 'keywords', content: 'datonique, analytics, data, reporting, visualization, data science, data warehouse, sdk, digital transformation, growth marketing' },
          { property: 'og:title', content: 'Home' },
          { proprety: 'og:description', content: 'Datonique specializes in data analytics, digital transformation and growth marketing. Our products are analytics sdk, reporting portal and data warehouse.' },
        ]
      }
    }
  },
  { path: 'WebSDK', component: WebsdkComponent, 
    data : {seo: {
        title: 'Web SDK',
        metaTags: [
          { name: 'description', content: 'Web SDK measures user activity in websites, web portals' },
          { name: 'keywords', content: 'web sdk, measurement, websites, portals, web analytics, metrics, reporting, session' },
          { property: 'og:title', content: 'Web SDK' },
          { proprety: 'og:description', content: 'Web SDK measures user activity in websites, web portals' },
        ]
      }
    }
  },
  { path: 'MobileSDK', component: MobilesdkComponent,
    data : {seo: {
        title: 'Mobile SDK',
        metaTags: [
          { name: 'description', content: 'Mobile SDK measures user activity on mobile devices' },
          { name: 'keywords', content: 'mobile sdk, mobile apps, measurement, mobile analytics, metrics, reporting, app starts' },
          { property: 'og:title', content: 'Mobile SDK' },
          { proprety: 'og:description', content: 'Mobile SDK measures user activity on mobile devices' },
        ]
      }
    }
  },
  { path: 'DataWarehouse', component: WarehouseComponent, 
    data : {seo: {
        title: 'Data Warehouse',
        metaTags: [
          { name: 'description', content: 'We have developed Unified Cloud Data Warehouses for several clients integrating all their data sources into one single instance seamlessly. We develop a solution that fits the client’s current stack, needs and vision.' },
          { name: 'keywords', content: 'AWS, Microsoft Azure, Google Cloud, MySQL, Vertica, Data Warehouse, Infrastructure' },
          { property: 'og:title', content: 'Data Warehouse' },
          { proprety: 'og:description', content: 'We have developed Unified Cloud Data Warehouses for several clients integrating all their data sources into one single instance seamlessly. We develop a solution that fits the client’s current stack, needs and vision.' },
        ]
      }
    }
  },
  { path: 'StagingPlatform', component: StagingplatformComponent, 
    data : {seo: {
        title: 'Data Staging Platform',
        metaTags: [
          { name: 'description', content: 'We provide a data staging platform that builds a structured data pipeline from various data sources for reporting and analytics. It integrates all data sources into one single unified database and updates the data regularly through scheduled ETL processes' },
          { name: 'keywords', content: 'Database-as-a-service, Data Staging Platform, ETL, Automatic ETL, Integrations' },
          { property: 'og:title', content: 'Data Staging Platform' },
          { proprety: 'og:description', content: 'We provide a data staging platform that builds a structured data pipeline from various data sources for reporting and analytics. It integrates all data sources into one single unified database and updates the data regularly through scheduled ETL processes' },
        ]
      }
    }
  },
  { path: 'StandardPortal', component: StandardportalComponent, 
    data : {seo: {
        title: 'Standard Portal',
        metaTags: [
          { name: 'description', content: 'We have our own analytics portal product that clients can use as an off-the-shelf product. ' },
          { name: 'keywords', content: 'Reporting Portal, Standard Portal, Tableau' },
          { property: 'og:title', content: 'Standard Portal' },
          { proprety: 'og:description', content: 'We have our own analytics portal product that clients can use as an off-the-shelf product. ' },
        ]
      }
    }
  },
  { path: 'CustomPortal', component: CustomportalComponent, 
    data : {seo: {
        title: 'Custom Portal',
        metaTags: [
          { name: 'description', content: 'We can white label the portal to suit the client’s needs and requirements.' },
          { name: 'keywords', content: 'Reporting, Custom Portal, Tableau' },
          { property: 'og:title', content: 'Custom Portal' },
          { proprety: 'og:description', content: 'We can white label the portal to suit the client’s needs and requirements.' },
        ]
      }
    }
  },
  { path: 'DataInfrastructure', component: DatainfraComponent, 
    data : {seo: {
        title: 'Data Infrastructure',
        metaTags: [
          { name: 'description', content: 'We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have worked with everything.' },
          { name: 'keywords', content: 'AWS, Microsoft Azure, Google Cloud, MySQL, Vertica, Data Warehouse' },
          { property: 'og:title', content: 'Data Infrastructure' },
          { proprety: 'og:description', content: 'We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have worked with everything.' },
        ]
      }
    }
  },
  { path: 'Report', component: ReportingComponent, 
    data : {seo: {
        title: 'Reporting and Visualization',
        metaTags: [
          { name: 'description', content: 'We have our own reporting portal. We also work with other systems and tools like Tableau, Qlikview, Amazon Quicksight, Microstrategy.' },
          { name: 'keywords', content: 'Reporting, Visualization, KPIs, Tableau, Qlikview' },
          { property: 'og:title', content: 'Reporting and Visualization' },
          { proprety: 'og:description', content: 'We have our own reporting portal. We also work with other systems and tools like Tableau, Qlikview, Amazon Quicksight, Microstrategy.' },
        ]
      }
    }
  },
  { path: 'DataScience', component: DatascienceComponent, 
    data : {seo: {
        title: 'Data Science',
        metaTags: [
          { name: 'description', content: 'The third pillar involves driving deeper insights, data science initiatives (AI/Machine Learning/Predictive Modeling) that ask and answer some difficult questions that require the use of sophisticated analyses such as predictive analytics, regression modeling, principal component analysis (PCA), Machine Learning etc.' },
          { name: 'keywords', content: 'Data Science, Machine Learning, AI, ML, Insights, Predictive Modeling, Forecasting' },
          { property: 'og:title', content: 'Data Science' },
          { proprety: 'og:description', content: 'The third pillar involves driving deeper insights, data science initiatives (AI/Machine Learning/Predictive Modeling) that ask and answer some difficult questions that require the use of sophisticated analyses such as predictive analytics, regression modeling, principal component analysis (PCA), Machine Learning etc.' },
        ]
      }
    }
  },
  { path: 'Marketing', component: MarketingComponent, 
    data : {seo: {
        title: 'Growth Marketing / Growth Hacking',
        metaTags: [
          { name: 'description', content: 'Growth marketing/hacking applies to startups or companies that plan to grow customers, users or digital footprint substantially.' },
          { name: 'keywords', content: 'Growth Marketing, Growth Hacking, Users, Growth, User Acquisition' },
          { property: 'og:title', content: 'Growth Marketing / Growth Hacking' },
          { proprety: 'og:description', content: 'Growth marketing/hacking applies to startups or companies that plan to grow customers, users or digital footprint substantially.' },
        ]
      }
    }
  },
  { path: 'Customer', component: CustomerComponent, 
    data : {seo: {
        title: 'Customer Success Stories',
        metaTags: [
          { name: 'description', content: 'Information on customer success stories, case studies, happy clients.' },
          { name: 'keywords', content: 'Customer success stories, case studies, happy clients' },
          { property: 'og:title', content: 'Customer Success Stories' },
          { proprety: 'og:description', content: 'Information on customer success stories, case studies, happy clients.' },
        ]
      }
    }
  },
  { path: 'ContactUs', component: ContactusComponent, 
    data : {seo: {
        title: 'Contact Us',
        metaTags: [
          { name: 'description', content: 'Contact us at hello@datonique.com for anything you need help with.' },
          { name: 'keywords', content: 'Contact us, Email, Phone, hello@datonique.com, Datonique, Data Analytics, Digital Transformation, Growth Marketing' },
          { property: 'og:title', content: 'Contact Us' },
          { proprety: 'og:description', content: 'Contact us at hello@datonique.com for anything you need help with.' },
        ]
      }
    }
  },
  { path: 'Mission', component: MissionComponent, 
    data : {seo: {
        title: 'Mission',
        metaTags: [
          { name: 'description', content: 'We bring easy to understand data and analytics to life and leverage these insights to bring real ROI and revenue to you and your company at a fraction of the cost of an in-house analytics team.' },
          { name: 'keywords', content: 'Contact Us, Easy to Understand, ROI, Leverage Data, Reporting, Visualization, Analytics, Digital Transformation, Growth Marketing' },
          { property: 'og:title', content: 'Mission' },
          { proprety: 'og:description', content: 'We bring easy to understand data and analytics to life and leverage these insights to bring real ROI and revenue to you and your company at a fraction of the cost of an in-house analytics team.' },
        ]
      }
    }
  },
  { path: 'Team', component: TeamComponent, 
    data : {seo: {
        title: 'Team',
        metaTags: [
          { name: 'description', content: 'Murali is a seasoned analytics professional, entrepreneur, product and strategy leader with 10+ years of experience in the technology space leading multiple teams across various companies in all spheres of the industry.' },
          { name: 'keywords', content: 'Murali, Vasudevan, Murali Vasudevan, Analytics Professional, Visionary, Technologist, Product Leader, Strategy, Data' },
          { property: 'og:title', content: 'Team' },
          { proprety: 'og:description', content: 'Murali is a seasoned analytics professional, entrepreneur, product and strategy leader with 10+ years of experience in the technology space leading multiple teams across various companies in all spheres of the industry.' },
        ]
      }
    }
  },
  { path: 'Testimonials', component: TestmonialsComponent, 
    data : {seo: {
        title: 'Client Testimonials',
        metaTags: [
          { name: 'description', content: 'Testimonials from clients happy to work with Datonique.' },
          { name: 'keywords', content: 'Testimonials, Clients, Murali, Murali Vasudevan, Datonique, Satisfied' },
          { property: 'og:title', content: 'Client Testimonials' },
          { proprety: 'og:description', content: 'Testimonials from clients happy to work with Datonique.' },
        ]
      }
    }
  },
  { path: 'Careers', component: CareersComponent, 
    data : {seo: {
        title: 'Careers',
        metaTags: [
          { name: 'description', content: 'Contact us at hiring@datonique.com with your resume' },
          { name: 'keywords', content: 'Backend Engineer, Data Scientist, Frontend Engineer, Data Engineer, Analyst, Tableau, AWS, Product Manager, Solutions Architect' },
          { property: 'og:title', content: 'Careers' },
          { proprety: 'og:description', content: 'Contact us at hiring@datonique.com with your resume' },
        ]
      }
    }
  },
  { path: 'AnalyticsSDK', component: AnalyticsdkComponent, 
    data : {seo: {
        title: 'Analytics SDK',
        metaTags: [
          { name: 'description', content: 'Analytics SDK helps companies measure user behavior with analytic events such as session, engagement, click, page view, purchase, referral etc' },
          { name: 'keywords', content: 'analytics sdk, measurement, events, session, active users, metrics, reporting, visualization' },
          { property: 'og:title', content: 'Analytics SDK' },
          { proprety: 'og:description', content: 'Analytics SDK helps companies measure user behavior with analytic events such as session, engagement, click, page view, purchase, referral etc' },
        ]
      }
    }
  },
  { path: 'Infrastructure', component: DatainfrastructureComponent, 
    data : {seo: {
        title: 'Data Infrastructure',
        metaTags: [
          { name: 'description', content: 'We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have worked with everything.' },
          { name: 'keywords', content: 'AWS, Microsoft Azure, Google Cloud, MySQL, Vertica, Data Warehouse' },
          { property: 'og:title', content: 'Data Infrastructure' },
          { proprety: 'og:description', content: 'We work with every technology and stack under the sun. Be it modern technology such as AWS Stack, Microsoft Cloud Stack, Elastic or Google Cloud Stack or traditional technologies such as MySQL, Vertica, Oracle we have worked with everything.' },
        ]
      }
    }
  },
  { path: 'ReportingPortal', component: ReportingportalComponent, 
    data : {seo: {
        title: 'Reporting Portal',
        metaTags: [
          { name: 'description', content: 'Standard or Custom Reporting Portal for clients' },
          { name: 'keywords', content: 'Reporting Portal, Standard Portal, Custom Portal, Tableau' },
          { property: 'og:title', content: 'Reporting Portal' },
          { proprety: 'og:description', content: 'Standard or Custom Reporting Portal for clients' },
        ]
      }
    }
  },
  { path: 'Services', component: ServicesComponent, 
    data : {seo: {
        title: 'Services',
        metaTags: [
          { name: 'description', content: 'We provide world class services related to Data Analytics, Digital Transformation and Growth Marketing.' },
          { name: 'keywords', content: 'Data Analytics, Data Infrastructure, Reporting, Visualization, Data Science, Digital Transformation, Growth Marketing' },
          { property: 'og:title', content: 'Services' },
          { proprety: 'og:description', content: 'We provide world class services related to Data Analytics, Digital Transformation and Growth Marketing.' },
        ]
      }
    }
  },
  { path: 'DigitalTransformation', component: DigitaltransformComponent, 
    data : {seo: {
        title: 'Digital Transformation',
        metaTags: [
          { name: 'description', content: 'We are experts in building new systems, overhauling or replacing archaic systems and technologies with scalable and futuristic systems through an organized and seasoned approach that has proven to be successful over several years of development.' },
          { name: 'keywords', content: 'New Systems, Upgrades, Enterprise Software Systems, Next Gen Development, Mobile Apps, Web Apps, Hybrid Systems' },
          { property: 'og:title', content: 'Digital Transformation' },
          { proprety: 'og:description', content: 'We are experts in building new systems, overhauling or replacing archaic systems and technologies with scalable and futuristic systems through an organized and seasoned approach that has proven to be successful over several years of development.' },
        ]
      }
    }
  },
  { path: 'Resources', component: ResourcesComponent,
    data : {seo: {
        title: 'Resources',
        metaTags: [
          { name: 'description', content: 'Resources at Datonique. Customer Success Stories, Contact Us.' },
          { name: 'keywords', content: 'Datonique, Resources, Case Studies, Customer Success Stories, About' },
          { property: 'og:title', content: 'Resources' },
          { proprety: 'og:description', content: 'Resources at Datonique. Customer Success Stories, Contact Us.' },
        ]
      }
    }
  },
  { path: 'About', component: AboutComponent,
    data : {seo: {
        title: 'About',
        metaTags: [
          { name: 'description', content: 'About Datonique - Mission, Careers, Founder, Client Testimonials' },
          { name: 'keywords', content: 'Mission, Careers, Founder, Client Testimonials' },
          { property: 'og:title', content: 'About' },
          { proprety: 'og:description', content: 'About Datonique - Mission, Careers, Founder, Client Testimonials' },
        ]
      }
    }
  },
  { path: 'DataAnalytics', component: DatanalyticsComponent,
    data : {seo: {
        title: 'Data Analytics',
        metaTags: [
          { name: 'description', content: 'The 3 pillars of data analytics - data infrastructure, reporting and visualization and data science' },
          { name: 'keywords', content: 'Data Analytics, Data Infrastructure, Reporting, Visualization, Data Science, KPIs' },
          { property: 'og:title', content: 'Data Analytics' },
          { proprety: 'og:description', content: 'The 3 pillars of data analytics - data infrastructure, reporting and visualization and data science' },
        ]
      }
    }
  },
  { path: 'Products', component: ProductsComponent,
    data : {seo: {
        title: 'Products',
        metaTags: [
          { name: 'description', content: 'Datonique\'s products are Analytics SDK (Web SDK and Mobile SDK), Data Infrastructure Products (Data Warehouse and Data Staging Platform) and Reporting Portal (Standard and Custom)' },
          { name: 'keywords', content: 'analytics sdk, web sdk, mobile sdk, data infrastructure, data warehouse, data staging platform, reporting portal, custom portal, visualization, data products' },
          { property: 'og:title', content: 'Products' },
          { proprety: 'og:description', content: 'Datonique\'s products are Analytics SDK (Web SDK and Mobile SDK), Data Infrastructure Products (Data Warehouse and Data Staging Platform) and Reporting Portal (Standard and Custom)' },
        ]
      }
    }
  },
  { path: 'Search', component: SearchComponent,
    data : {seo: {
        title: 'Search',
        metaTags: [
          { name: 'description', content: 'search datonique website' },
          { name: 'keywords', content: 'datonique, search, tableau' },
          { property: 'og:title', content: 'Search' },
          { proprety: 'og:description', content: 'search datonique website' },
        ]
      }
    }
  },
  { path: 'Blog', component: BlogComponent,
    data : {seo: {
        title: 'Blog',
        metaTags: [
          { name: 'description', content: 'blog datonique website' },
          { name: 'keywords', content: 'datonique, blog, tableau' },
          { property: 'og:title', content: 'Blog' },
          { proprety: 'og:description', content: 'blog datonique website' },
        ]
      }
    }
  },
  { path: 'Blog/:id/:title', component: BlogDetailComponent,
    data : {seo: {
        title: 'Blog',
        metaTags: [
          { name: 'description', content: 'blog datonique website' },
          { name: 'keywords', content: 'datonique, blog, tableau' },
          { property: 'og:title', content: 'Blog' },
          { proprety: 'og:description', content: 'blog datonique website' },
        ]
      }
    }
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', useHash: true,
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

