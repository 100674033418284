<!-- Page top section  -->
	<section class="page-top-section set-bg" data-setbg="assets/img/page-top-bg/1.jpg">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<h2>Services</h2>
					<p>Digital Transformation  </p>
				
				</div>
			</div>
		</div>
	</section>
	<!-- Page top section end  -->


	<!-- About section -->
	<section class="blog-section spad">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-6 sidebar order-2 order-lg-1">
					
					
					
					<div class="sb-widget">
						<div class="info-box" >
							<h3>Contact Us for Help</h3>
							<p style="color:#f1f1f1;">You can contact us to know more about our products and services. </p>
							<div class="footer-info-box">
								<div class="fib-icon">
									<img src="assets/img/icons/phone.png" alt="" class="">
								</div>
								<div class="fib-text" >
									<p  style="color:#f1f1f1;">626-240-4600<br>
<a href="mailto:hello@datonique.com" style="text-decoration:none;">hello@datonique.com</a></p>
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 order-1 order-lg-2">
					<div class="blog-post">
												
						<h2>Digital Transformation</h2>
						<p>We are experts in building new systems, overhauling or replacing archaic systems and technologies with scalable and futuristic systems through an organized and seasoned approach that has proven to be successful over several years of development. The technologies include</p>
						<ul style="list-style-type:none;">
						<li><i class="fa fa-check" style="color:#e58202;"></i> Enterprise Software Systems<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Next Gen Web Products<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Modern and Responsive Mobile Apps<li>
						<li><i class="fa fa-check" style="color:#e58202;"></i> Hybrid Systems<li>
						</ul>
						



						
						
					</div>
					
					
					
				</div>
			</div>
		</div>
	</section>