<!-- Call to action section  -->
<section class="cta-section">
   <div class="container">
      <div class="row">
         <div class="col-lg-9 d-flex align-items-center">
            <h2>"We help you take control of your data and digital destiny immediately"</h2>
         </div>
         <div class="col-lg-3 text-lg-right" >
            <a href="javascript://" routerLinkActive="active" routerLink="/ContactUs" class="site-btn sb-dark">contact us</a>
         </div>
      </div>
   </div>
</section>
<!-- Call to action section end  -->

<!-- Footer section -->
<footer class="footer-section spad" style="border-top:2px solid #fff;">
   <div class="container">
      <div class="row">
         <div class="col-lg-6 col-md-6">
            <div class="footer-widget about-widget">
               <img src="assets/img/logo_small.png" alt="">
               <p class="footcolor">We bring easy to understand data and analytics to life and leverage these insights to bring real ROI and revenue to you and your company at a fraction of the cost of an in-house analytics team.</p>
               <p class="footcolor" style="margin-top:-20px;">We are also your next generation digital transformation and growth marketing partner, helping transform the digital footprint to conquer your addressable market</p>
            </div>
         </div>
         <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="footer-widget">
               <h2 class="fw-title">Useful Resources</h2>
               <ul>
                  <li><a href="javascript://" routerLinkActive="active" routerLink="/index">Home</a></li>
                  <li><a href="javascript://" routerLinkActive="active" routerLink="/Careers">Careers</a></li>
                  <li><a href="javascript://" routerLinkActive="active" routerLink="/Testimonials">Client Testimonials</a></li>
                  <li><a href="javascript://" routerLinkActive="active" routerLink="/ContactUs">Contact</a></li>
               </ul>
            </div>
         </div>
         <div class="col-lg-3 col-md-6 col-sm-7">
            <div class="footer-widget">
               <h2 class="fw-title">Contact Us</h2>
               <div class="footer-info-box">
                  <div class="fib-icon" style="padding-top:0px;">
                     <i class="fa fa-phone" style="color:#4c96d7; font-size:1.5em;"></i>
                  </div>
                  <div class="fib-text">
                     <p  class="footcolor">626-240-4600</p>
                  </div>
               </div>
               <div class="footer-info-box">
                  <div class="fib-icon" style="padding-top:0px;">
                     <i class="fa fa-envelope" style="color:#4c96d7; font-size:1.5em;"></i>
                  </div>
                  <div class="fib-text">
                     <p class="footcolor"><a href="mailto:hello@datonique.com" style="text-decoration:none;" class="logtext">hello@datonique.com</a></p>
                  </div>
               </div>
               <div class="footer-social">
                  <a href="javascript://"><i class="fa fa-linkedin"></i></a>
                  <a href="javascript://"><i class="fa fa-facebook"></i></a>
                  <a href="javascript://"><i class="fa fa-twitter"></i></a>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="footer-buttom">
      <div class="container">
         <div class="row">
            <div class="col-lg-4 order-2 order-lg-1 p-0">
               <div class="copyright" style="padding-bottom:10px;">
                  <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                  Copyright &copy; All rights reserved | Datonique <br>
               </div>
            </div>
            <div class="col-lg-7 order-1 order-lg-2 p-0">
            </div>
         </div>
      </div>
   </div>
</footer>
<!-- Footer section end -->