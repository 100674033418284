import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError, RouterModule, ActivatedRoute} from "@angular/router";
import { CustomService } from '../../../services/custom.service';   
import { environment } from '../../../../environments/environment';  

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit, OnDestroy  {
  articles:any = [];
  tagsArr = ["datonique","analytics","portal","report","sql query","data","new currency","unify","leverage data","tracking","metrics","definition","measurement","kpis","customer behavior","engagement","data integrity","hygiene","clean data","roi","data ftw","revenue value of data","revenue","not a cost center","data drives revenue"];
  blogtags = 'All';
  tagid = '';
  dateid = '';
  paramsSub:any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private actRouter: ActivatedRoute,
    private titleService: Title,
    private cService: CustomService
  ) { 
    this.cService.onLoadScript();
    this.paramsSub = this.actRouter.queryParams.subscribe(params => {
      this.tagid = params['tag'];
      this.dateid = params['date'];
      this.blogtags = (this.tagid !== undefined)?this.tagid:'All';
      this.getBlog(this.tagid,this.dateid);
    });
    this.titleService.setTitle( 'Blog' );
  }

  ngOnInit(): void {
    
  }

  searchTags() {
    if(this.blogtags !== 'All') {
      this.router.navigate(['/Blog'], { queryParams: { tag: this.blogtags } });
    } else {
      this.router.navigate(['/Blog']);
    }
  }

  getBlog(tag, dateid) {
    this.http.get<any>(environment.localPath+'articles.json').subscribe(data => {
        this.articles = data;
        if(tag !== undefined && tag !== "") {
          this.articles = this.articles.filter(t=>t.tags.indexOf(tag) > -1);
        }
        if(dateid !== undefined && dateid !== "") {
          this.articles = this.articles.filter(t=>t.date.includes(dateid));
        }
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }


}
