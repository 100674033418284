import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { CustomService } from './services/custom.service';
import { SeoService } from './services/seo.service';

declare var $: any;
declare var WhWidgetSendButton;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent implements OnInit {
  	title = 'Datonique';
  	//window._CallMe();
  	constructor(
  		private cService: CustomService,
  		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private metaService: Meta,
		private seoService: SeoService
  	) {
  		if (this.cService.loadVar==undefined) {    
	      this.cService.loadVar = this.cService.    
	      invokeOnloadScript.subscribe((name:string) => {    
	        this.loadScripts();
	      });    
	    }  	
  	}

	ngOnInit() {

		this.router.events.pipe(
	      filter(e => e instanceof NavigationEnd),
	      map(e => this.activatedRoute),
	      map((route) => {
	        while (route.firstChild) route = route.firstChild;
	        return route;
	      }),
	      filter((route) => route.outlet === 'primary'),
	      mergeMap((route) => route.data),
	    ).subscribe(data => {
	      let seoData = data['seo'];
	      this.seoService.updateTitle(this.title+' - '+seoData['title']);
	      this.seoService.updateMetaTags(seoData['metaTags']);
	    });


		/*this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.titleService.setTitle(this.title+' - '+this.activatedRoute.firstChild.snapshot.data.title);
				this.metaService.updateTag({ name: 'description', content: 'Datonique Founder and CEO' })
			    this.metaService.updateTag({ name: 'keywords', content: 'About, Founder, CEO' })
			    this.metaService.updateTag({ name: 'og:title', content: this.title+' - '+this.activatedRoute.firstChild.snapshot.data.title })
			    this.metaService.updateTag({ name: 'og:description', content: 'Datonique Founder and CEO' })				
			}
		});*/

		(function () {
        var options = {
            whatsapp: "+16266606162", // WhatsApp number
            call_to_action: "", // Call to action
            position: "right", // Position may be 'right' or 'left'
        };
        var proto = document.location.protocol, host = "getbutton.io", url = proto + "//static." + host;
        var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = url + '/widget-send-button/js/init.js';
        s.onload = function () { WhWidgetSendButton.init(host, proto, options); };
        var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x);
    })();
		
		/*this.addJsToElement('assets/js/main.js').onload = (teste) => {
			console.log(teste)
			//console.log(DLECC.init());
		}*/
	}

	/*addJsToElement(src: string): HTMLScriptElement {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = src;
		this.renderer.appendChild(document.body, script);
		return script;
	}*/

	loadScripts() {   
		// This array contains all the files/CDNs 
		const dynamicScripts = [ 
		'assets/js/main.js'
		]; 
		for (let i = 0; i < dynamicScripts.length; i++) { 
			const node = document.createElement('script'); 
			node.src = dynamicScripts[i]; 
			node.type = 'text/javascript'; 
			node.async = false; 
			document.getElementsByTagName('head')[0].appendChild(node); 
		} 
	} 
}
