import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
  	private router: Router
  ) { }

  ngOnInit(): void {
  	
	$(document).ready(function() {   //same as: $(function() { 
  		$('.stellarnav').stellarNav({
			theme: 'dark',
			breakpoint: 960,
			position: 'right',
			phoneBtn: '',
			locationBtn: ''
		});


		$('.search-switch').on('click', function() {
			$('.search-model').fadeIn(400);
		});

		$('.search-close-switch').on('click', function() {
			$('.search-model').fadeOut(400,function(){
				$('#search-input').val('');
			});
		});

		$('.site-nav-menu > ul').slicknav({
			appendTo:'.header-section',
			closedSymbol: '<i class="fa fa-angle-down"></i>',
			openedSymbol: '<i class="fa fa-angle-up"></i>',
			allowParentLinks: true
		});

		$('.slicknav_nav').append('<li class="search-switch-warp"><button class="search-switch"><i class="fa fa-search"></i></button></li>');
	});
  }

	goSearch(srchTxt) {
		this.router.navigate(['/Search'], { queryParams: { q: srchTxt } });
	}

}
