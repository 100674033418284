import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CustomService } from '../../../services/custom.service';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  isLoader = true;
  contactForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: boolean = false;
  errorMsg = '';
  mailText = '';
  resetForm:any;

  constructor(
  	private cService: CustomService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
  	//this.loadScripts();
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {

    this.contactForm = this.formBuilder.group({
      topic: ''
    });

    this.resetForm = {
        "name": "",
        "email": "",
        "phoneno": "",
        "company": "",
        "title": "",
        "topic": "",
        "description": "",
        "findus": ""
    };

    this.contactForm = new FormGroup({
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)
      ]),
      phoneno: new FormControl(''),
      company: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      topic: new FormControl('', [
        Validators.required
      ]),
      findus: new FormControl('')
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
        this.submitted = true;
        //const api_key = 'JGIji7rUC69xIcP7ox60k8TyY28pLWm2aLGNUsbu'
        //const stage = 'prod'

        // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
        }
        this.error = false;
        this.loading = true;
        $("#overlay").fadeIn();
        $(".loader").fadeIn();
        
        //this.f.name.value
        var formObj = {
          "name": this.f.name.value,
          "email": this.f.email.value,
          "phone_number": this.f.phoneno.value,
          "company": this.f.company.value,
          "title": this.f.title.value,
          "topic": this.f.topic.value,
          "description": this.f.description.value,
          "howFindUs": this.f.findus.value
        };

        const headers = { 'x-api-key': environment.apiKey};

        this.http.post<any>(`https://su6jemfjrk.execute-api.us-west-2.amazonaws.com/${environment.stage}/mail`, formObj, { headers }).subscribe({
            next: data => {
            this.toastr.success('Mail sent successfully', 'Thank You');

            setTimeout (() => {
              $( "#resetBtn" ).trigger( "click" );
            }, 500);
        },
        error: error => {
          //this.errorMessage = error.message;
          //console.error('There was an error!', error);
          this.toastr.error('Mail not sent, please try again later.', 'Error');
          
          setTimeout (() => {
            $( "#resetBtn" ).trigger( "click" );
          }, 500);
      }
    
      });      
        
    }

    onReset() {
      this.loading = false;
      $("#overlay").fadeOut();
      $(".loader").fadeOut();
      this.contactForm.reset(this.resetForm);
    }

  myLoadEvent(){ this.isLoader = false; }

}
