import { Component, OnInit } from '@angular/core';
import { CustomService } from '../../../services/custom.service';   

@Component({
  selector: 'app-reportingportal',
  templateUrl: './reportingportal.component.html',
  styleUrls: ['./reportingportal.component.css']
})
export class ReportingportalComponent implements OnInit {

  constructor(
  	private cService: CustomService
  ) {
  	this.cService.onLoadScript(); 
  }

  ngOnInit(): void {
  }

}
