import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomService } from '../../../services/custom.service'; 
import { environment } from '../../../../environments/environment';
import * as pData from  '../../../../assets/json/pages.json';


declare var $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {
  
  paramsSub:any;
  srchTxt:any;
  srchResult:any;
  pageData: any = (pData as any).default;
  /** safe html of google custom search API box */
  //gcseSearchBox: SafeHtml;
  /** safe html of google custom search API result */
  //gcseSearchResult: SafeHtml;

  constructor(
  	private router: Router,
  	private actRouter: ActivatedRoute,
  	private readonly sanitizer: DomSanitizer,
    private cService: CustomService
  ) { 
    this.cService.onLoadScript(); 
    
  	this.paramsSub = this.actRouter.queryParams.subscribe(params => {
      if(params.q != undefined) {
        this.srchTxt = params.q;
        //this.onLoasSrch(this.srchTxt);
        this.srchContent(this.srchTxt);
      }
    });
  }

  ngOnInit(): void {
  	/*this.gcseSearchBox = this.sanitizer.bypassSecurityTrustHtml(
        '<gcse:searchbox></gcse:searchbox>'
    );
    this.gcseSearchResult = this.sanitizer.bypassSecurityTrustHtml('<gcse:searchresults></gcse:searchresults>');
    const cx = environment.gsID;
    const gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);  */
  }

  goSearch(srchTxt) {
    this.router.navigate(['/Search'], { queryParams: { q: srchTxt } });
  }

  srchContent(srchTxt) {
    this.srchTxt = srchTxt;
    var sTxt = srchTxt.toLowerCase();
    this.srchResult = this.pageData.filter((x) => x.title.toLowerCase().includes(sTxt) || x.description.toLowerCase().includes(sTxt));
    
    /*var marvelHeroes =  heroes.filter(function(hero) {
      return hero.franchise == "Marvel";

    });*/
  }

  /*onLoasSrch(srchTxt) {

  	setTimeout(function(){
      $( "#gsc-i-id1" ).val(srchTxt);
      $( "#gsc-i-id1" ).focus();
      $( ".gsc-search-button" ).click();
    },500);

  }*/

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

}
